import { useState } from "react";
import { StaticImage } from "gatsby-plugin-image";

import useDeviceDetect from "../../hooks/useDeviceDetect";
import useResponsiveWithHydrationFix from "../../hooks/useResponsiveWithHydrationFix";

import { ALButton, ALRadioNavigation, ALLink } from "../../components/ALComponents";

import Items2ImageDesktop from "../../images/subscription/luxe/pricing/2_items_image_desktop.jpg";
import Items3ImageDesktop from "../../images/subscription/luxe/pricing/3_items_image_desktop.jpg";
import SolidGoldImageDesktop from "../../images/subscription/luxe/pricing/solid_gold_image_desktop.jpg";

import * as Styles from "./priceComparisons/styles.module.scss";

function PriceComparisons() {
  const { isMobile } = useDeviceDetect();

  const [activeTab, setActiveTab] = useState("solid_gold");
  const [activeImageSrc, setActiveImageSrc] = useState(SolidGoldImageDesktop);

  const items2Image = useResponsiveWithHydrationFix(
    <StaticImage
      src="../../images/subscription/luxe/pricing/2_items_pricing_image_mobile.jpg"
      alt="2 items"
    />,
    <StaticImage
      src="../../images/subscription/luxe/pricing/2_items_pricing_image_desktop.jpg"
      alt="2 items"
    />
  );
  const items3Image = useResponsiveWithHydrationFix(
    <StaticImage
      src="../../images/subscription/luxe/pricing/3_items_pricing_image_mobile.jpg"
      alt="3 items"
    />,
    <StaticImage
      src="../../images/subscription/luxe/pricing/3_items_pricing_image_desktop.jpg"
      alt="3 items"
    />
  );

  const solidGoldImage = useResponsiveWithHydrationFix(
    <StaticImage
      src="../../images/subscription/luxe/pricing/solid_gold_pricing_image_mobile.jpg"
      alt="Solid gold"
    />,
    <StaticImage
      src="../../images/subscription/luxe/pricing/solid_gold_pricing_image_desktop.jpg"
      alt="Solid gold"
    />
  );

  const navigationElements = [
    { key: "solid_gold", content: "Solid Gold", imageSrc: SolidGoldImageDesktop },
    { key: "2_items", content: "2 items", imageSrc: Items2ImageDesktop },
    { key: "3_items", content: "3 items", imageSrc: Items3ImageDesktop },
  ];

  const onTabClick = (tab) => {
    setActiveTab(tab);
    const activeNavigation = navigationElements.find((el) => el.key === tab);
    setActiveImageSrc(activeNavigation.imageSrc);
  };

  return (
    <div className={Styles.priceComparisons}>
      <div className={Styles.priceComparisons__content}>
        <div className={Styles.priceComparisons__top}>
          <h2 className="h2 h2--bold h2--uc">Price Comparisons</h2>
          <ALRadioNavigation
            selectedKey={activeTab}
            elements={navigationElements}
            onClick={onTabClick}
            className="price-comparisons"
          />
        </div>

        <div className={Styles.priceComparisons__bottom}>
          <div>
            <div className={activeTab === "solid_gold" ? "" : "hidden"}>{solidGoldImage}</div>
            <div className={activeTab === "2_items" ? "" : "hidden"}>{items2Image}</div>
            <div className={activeTab === "3_items" ? "" : "hidden"}>{items3Image}</div>
          </div>
          <ALLink to="/collections/bestsellers/">
            <ALButton variant="primary" size="medium">
              START SHOPPING
            </ALButton>
          </ALLink>
        </div>
      </div>

      {!isMobile && (
        <img
          src={activeImageSrc}
          alt="Price Comparison"
          className={Styles.priceComparisons__image}
        />
      )}
    </div>
  );
}

export default PriceComparisons;
